import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(() => {
  "use strict";

  var n = {
    n: e => {
      var r = e && e.__esModule ? () => e.default : () => e;
      return n.d(r, {
        a: r
      }), r;
    },
    d: (e, r) => {
      for (var t in r) n.o(r, t) && !n.o(e, t) && Object.defineProperty(e, t, {
        enumerable: !0,
        get: r[t]
      });
    },
    o: (e, r) => Object.prototype.hasOwnProperty.call(e, r),
    r: e => {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }
  },
      e = {};
  n.r(e), n.d(e, {
    ClampToEdgeWrapping: () => x,
    LinearFilter: () => T,
    LinearMipMapLinearFilter: () => P,
    LinearMipMapNearestFilter: () => f,
    MirroredRepeatWrapping: () => v,
    NearestFilter: () => y,
    NearestMipMapLinearFilter: () => l,
    NearestMipMapNearestFilter: () => c,
    RepeatWrapping: () => R,
    default: () => q
  });

  var h,
      g,
      o = _react,
      a = n.n(o),
      b = function (e) {
    return "shadertoy-react: ".concat(e);
  };

  function w(e, r) {
    return r = r || e.slice(0), Object.freeze(Object.defineProperties(e, {
      raw: {
        value: Object.freeze(r)
      }
    }));
  }

  function i(e, r) {
    for (var t = 0; t < r.length; t++) {
      var n = r[t];
      n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
    }
  }

  function r(e, r, t) {
    return r && i(e.prototype, r), t && i(e, t), Object.defineProperty(e, "prototype", {
      writable: !1
    }), e;
  }

  function t(e, r, t) {
    return r in e ? Object.defineProperty(e, r, {
      value: t,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[r] = t, e;
  }

  function E(e) {
    return 0 == (e & e - 1);
  }

  function s(e) {
    return Math.pow(2, Math.floor(Math.log(e) / Math.LN2));
  }

  var u,
      y = 9728,
      T = 9729,
      c = 9984,
      f = 9985,
      l = 9986,
      P = 9987,
      x = 33071,
      v = 33648,
      R = 10497,
      d = r(function e(r) {
    var p = this || _global;
    !function (e, r) {
      if (!(e instanceof r)) throw new TypeError("Cannot call a class as a function");
    }(this || _global, e), t(this || _global, "isLoaded", !1), t(this || _global, "url", void 0), t(this || _global, "wrapS", void 0), t(this || _global, "wrapT", void 0), t(this || _global, "minFilter", void 0), t(this || _global, "magFilter", void 0), t(this || _global, "source", void 0), t(this || _global, "flipY", -1), t(this || _global, "width", 0), t(this || _global, "height", 0), t(this || _global, "_webglTexture", null), t(this || _global, "updateTexture", function (e, r, t) {
      var n = p.gl,
          i = n.RGBA,
          o = n.RGBA,
          a = n.UNSIGNED_BYTE;
      n.bindTexture(n.TEXTURE_2D, e), n.pixelStorei(n.UNPACK_FLIP_Y_WEBGL, t), n.texImage2D(n.TEXTURE_2D, 0, i, o, a, r);
    }), t(this || _global, "setupVideo", function (e) {
      var r = document.createElement("video"),
          t = !1,
          n = !1;
      r.autoplay = !0, r.muted = !0, r.loop = !0, r.crossOrigin = "anonymous";

      function i() {
        t && n && (p.isLoaded = !0);
      }

      return r.addEventListener("playing", function () {
        t = !0, p.width = r.videoWidth || 0, p.height = r.videoHeight || 0, i();
      }, !0), r.addEventListener("timeupdate", function () {
        n = !0, i();
      }, !0), r.src = e, r;
    }), t(this || _global, "makePowerOfTwo", function (e) {
      return e instanceof HTMLImageElement || e instanceof HTMLCanvasElement || e instanceof ImageBitmap ? (void 0 === p.pow2canvas && (p.pow2canvas = document.createElement("canvas")), p.pow2canvas.width = s(e.width), p.pow2canvas.height = s(e.height), p.pow2canvas.getContext("2d").drawImage(e, 0, 0, p.pow2canvas.width, p.pow2canvas.height), console.warn(b("Image is not power of two ".concat(e.width, " x ").concat(e.height, ". Resized to ").concat(p.pow2canvas.width, " x ").concat(p.pow2canvas.height, ";"))), p.pow2canvas) : e;
    }), t(this || _global, "load", function (n, e) {
      var i = p.gl,
          o = n.url,
          r = n.wrapS,
          t = n.wrapT,
          a = n.minFilter,
          s = n.magFilter,
          u = n.flipY,
          c = void 0 === u ? -1 : u;
      if (!o) return Promise.reject(new Error(b(h = h || w(["Missing url, please make sure to pass the url of your texture { url: ... }"]))));
      var f = /(\.jpg|\.jpeg|\.png|\.gif|\.bmp)$/i.exec(o),
          u = /(\.mp4|\.3gp|\.webm|\.ogv)$/i.exec(o);
      if (null === f && null === u) return Promise.reject(new Error(b(g = g || w(["Please upload a video or an image with a valid format"])), o));
      Object.assign(p, {
        url: o,
        wrapS: r,
        wrapT: t,
        minFilter: a,
        magFilter: s,
        flipY: c
      });
      var l = i.RGBA,
          v = i.RGBA,
          d = i.UNSIGNED_BYTE,
          s = new Uint8Array([255, 255, 255, 0]),
          m = i.createTexture();

      if (i.bindTexture(i.TEXTURE_2D, m), i.texImage2D(i.TEXTURE_2D, 0, l, 1, 1, 0, v, d, s), u) {
        u = p.setupVideo(o);
        return i.texParameteri(i.TEXTURE_2D, i.TEXTURE_WRAP_S, i.CLAMP_TO_EDGE), i.texParameteri(i.TEXTURE_2D, i.TEXTURE_WRAP_T, i.CLAMP_TO_EDGE), i.texParameteri(i.TEXTURE_2D, i.TEXTURE_MIN_FILTER, i.LINEAR), p._webglTexture = m, p.source = u, p.isVideo = !0, u.play().then(function () {
          return p;
        });
      }

      return new Promise(function (e, r) {
        var t = new Image();
        t.crossOrigin = "anonymous", t.onload = function () {
          return e(t);
        }, t.onerror = function () {
          return r(new Error(b("failed loading url: ".concat(o))));
        }, t.src = o;
      }).then(function (e) {
        var r,
            t = E(e.width) && E(e.height);
        return ((r = n).wrapS !== x || r.wrapT !== x || r.minFilter !== y && r.minFilter !== T) && !1 === t && (e = p.makePowerOfTwo(e), t = !0), i.bindTexture(i.TEXTURE_2D, m), i.pixelStorei(i.UNPACK_FLIP_Y_WEBGL, c), i.texImage2D(i.TEXTURE_2D, 0, l, v, d, e), r = n, t && r.minFilter !== y && r.minFilter !== T && i.generateMipmap(i.TEXTURE_2D), i.texParameteri(i.TEXTURE_2D, i.TEXTURE_WRAP_S, p.wrapS || R), i.texParameteri(i.TEXTURE_2D, i.TEXTURE_WRAP_T, p.wrapT || R), i.texParameteri(i.TEXTURE_2D, i.TEXTURE_MIN_FILTER, p.minFilter || P), i.texParameteri(i.TEXTURE_2D, i.TEXTURE_MAG_FILTER, p.magFilter || T), p._webglTexture = m, p.source = e, p.isVideo = !1, p.isLoaded = !0, p.width = e.width || 0, p.height = e.height || 0, p;
      });
    }), (this || _global).gl = r;
  });

  function m(e) {
    switch (e) {
      case "1f":
        return M;

      case "2f":
        return "vec2";

      case "3f":
        return "vec3";

      case "4f":
        return "vec4";

      case "1i":
        return O;

      case "2i":
        return "ivec2";

      case "3i":
        return "ivec3";

      case "4i":
        return "ivec4";

      case "1iv":
        return O;

      case "2iv":
        return "ivec2";

      case "3iv":
        return "ivec3";

      case "4iv":
        return "ivec4";

      case "1fv":
        return "float";

      case "2fv":
        return "vec2";

      case "3fv":
        return "vec3";

      case "4fv":
        return "vec4";

      case "Matrix2fv":
        return "mat2";

      case "Matrix3fv":
        return "mat3";

      case "Matrix4fv":
        return "mat4";

      default:
        console.error(b(u || (r = ["The uniform type \"", "\" is not valid, please make sure your uniform type is valid"], t = t || r.slice(0), u = Object.freeze(Object.defineProperties(r, {
          raw: {
            value: Object.freeze(t)
          }
        }))), e));
    }

    var r, t;
  }

  var p,
      _,
      O = "int",
      M = "float";

  function L(e) {
    return (L = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    })(e);
  }

  function A(r, e) {
    var t,
        n = Object.keys(r);
    return Object.getOwnPropertySymbols && (t = Object.getOwnPropertySymbols(r), e && (t = t.filter(function (e) {
      return Object.getOwnPropertyDescriptor(r, e).enumerable;
    })), n.push.apply(n, t)), n;
  }

  function S(r) {
    for (var e = 1; e < arguments.length; e++) {
      var t = null != arguments[e] ? arguments[e] : {};
      e % 2 ? A(Object(t), !0).forEach(function (e) {
        k(r, e, t[e]);
      }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(r, Object.getOwnPropertyDescriptors(t)) : A(Object(t)).forEach(function (e) {
        Object.defineProperty(r, e, Object.getOwnPropertyDescriptor(t, e));
      });
    }

    return r;
  }

  function U(e, r) {
    return r = r || e.slice(0), Object.freeze(Object.defineProperties(e, {
      raw: {
        value: Object.freeze(r)
      }
    }));
  }

  function D(e) {
    return function (e) {
      if (Array.isArray(e)) return C(e);
    }(e) || function (e) {
      if ("undefined" != typeof Symbol && null != e[Symbol.iterator] || null != e["@@iterator"]) return Array.from(e);
    }(e) || function (e, r) {
      if (e) {
        if ("string" == typeof e) return C(e, r);
        var t = Object.prototype.toString.call(e).slice(8, -1);
        return "Map" === (t = "Object" === t && e.constructor ? e.constructor.name : t) || "Set" === t ? Array.from(e) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? C(e, r) : void 0;
      }
    }(e) || function () {
      throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }();
  }

  function C(e, r) {
    (null == r || r > e.length) && (r = e.length);

    for (var t = 0, n = new Array(r); t < r; t++) n[t] = e[t];

    return n;
  }

  function F(e, r) {
    for (var t = 0; t < r.length; t++) {
      var n = r[t];
      n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
    }
  }

  function j(e, r) {
    return (j = Object.setPrototypeOf || function (e, r) {
      return e.__proto__ = r, e;
    })(e, r);
  }

  function N(t) {
    var n = function () {
      if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
      if (Reflect.construct.sham) return !1;
      if ("function" == typeof Proxy) return !0;

      try {
        return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})), !0;
      } catch (e) {
        return !1;
      }
    }();

    return function () {
      var e,
          r = B(t);
      return function (e, r) {
        {
          if (r && ("object" === L(r) || "function" == typeof r)) return r;
          if (void 0 !== r) throw new TypeError("Derived constructors may only return object or undefined");
        }
        return I(e);
      }(this || _global, n ? (e = B(this || _global).constructor, Reflect.construct(r, arguments, e)) : r.apply(this || _global, arguments));
    };
  }

  function I(e) {
    if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return e;
  }

  function B(e) {
    return (B = Object.setPrototypeOf ? Object.getPrototypeOf : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    })(e);
  }

  function k(e, r, t) {
    return r in e ? Object.defineProperty(e, r, {
      value: t,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[r] = t, e;
  }

  function X(e, r, t) {
    return e * (1 - t) + r * t;
  }

  var z = ["lowp", "mediump", "highp"],
      W = "attribute vec3 aVertexPosition;\nvoid main(void) {\n    gl_Position = vec4(aVertexPosition, 1.0);\n}",
      Y = "iTimeDelta",
      G = "iResolution",
      V = "iChannelResolution",
      H = "iDeviceOrientation",
      q = function () {
    !function (e, r) {
      if ("function" != typeof r && null !== r) throw new TypeError("Super expression must either be null or a function");
      e.prototype = Object.create(r && r.prototype, {
        constructor: {
          value: e,
          writable: !0,
          configurable: !0
        }
      }), Object.defineProperty(e, "prototype", {
        writable: !1
      }), r && j(e, r);
    }(i, o.Component);
    var e,
        r,
        t,
        n = N(i);

    function i(e) {
      var u;
      return function (e, r) {
        if (!(e instanceof r)) throw new TypeError("Cannot call a class as a function");
      }(this || _global, i), k(I(u = n.call(this || _global, e)), "componentDidMount", function () {
        u.initWebGL();
        var e = u.props,
            r = e.fs,
            t = e.vs,
            n = e.clearColor,
            e = void 0 === n ? [0, 0, 0, 1] : n,
            n = I(u).gl;
        n && (n.clearColor.apply(n, D(e)), n.clearDepth(1), n.enable(n.DEPTH_TEST), n.depthFunc(n.LEQUAL), n.viewport(0, 0, u.canvas.width, u.canvas.height), u.canvas.height = u.canvas.clientHeight, u.canvas.width = u.canvas.clientWidth, u.processCustomUniforms(), u.processTextures(), t = u.preProcessShaders(r || "void mainImage( out vec4 fragColor, in vec2 fragCoord ) {\n    vec2 uv = fragCoord/iResolution.xy;\n    vec3 col = 0.5 + 0.5*cos(iTime+uv.xyx+vec3(0,2,4));\n    fragColor = vec4(col,1.0);\n}", t || W), u.initShaders(t), u.initBuffers(), u.drawScene(), u.addEventListeners(), u.onResize());
      }), k(I(u), "shouldComponentUpdate", function () {
        return !1;
      }), k(I(u), "setupChannelRes", function (e, r) {
        var t = e.width,
            n = e.height,
            e = u.props.devicePixelRatio,
            e = void 0 === e ? 1 : e;
        u.uniforms.iChannelResolution.value[3 * r] = t * e, u.uniforms.iChannelResolution.value[3 * r + 1] = n * e, u.uniforms.iChannelResolution.value[3 * r + 2] = 0;
      }), k(I(u), "initWebGL", function () {
        var e = u.props.contextAttributes;
        u.gl = u.canvas.getContext("webgl", e) || u.canvas.getContext("experimental-webgl", e), u.gl.getExtension("OES_standard_derivatives"), u.gl.getExtension("EXT_shader_texture_lod");
      }), k(I(u), "initBuffers", function () {
        var e = I(u).gl;
        u.squareVerticesBuffer = e.createBuffer(), e.bindBuffer(e.ARRAY_BUFFER, u.squareVerticesBuffer);
        e.bufferData(e.ARRAY_BUFFER, new Float32Array([1, 1, 0, -1, 1, 0, 1, -1, 0, -1, -1, 0]), e.STATIC_DRAW);
      }), k(I(u), "addEventListeners", function () {
        var e = {
          passive: !0
        };
        u.uniforms.iMouse.isNeeded && (u.canvas.addEventListener("mousemove", u.mouseMove, e), u.canvas.addEventListener("mouseout", u.mouseUp, e), u.canvas.addEventListener("mouseup", u.mouseUp, e), u.canvas.addEventListener("mousedown", u.mouseDown, e), u.canvas.addEventListener("touchmove", u.mouseMove, e), u.canvas.addEventListener("touchend", u.mouseUp, e), u.canvas.addEventListener("touchstart", u.mouseDown, e)), u.uniforms.iDeviceOrientation.isNeeded && window.addEventListener("deviceorientation", u.onDeviceOrientationChange, e), window.addEventListener("resize", u.onResize, e);
      }), k(I(u), "removeEventListeners", function () {
        var e = {
          passive: !0
        };
        u.uniforms.iMouse.isNeeded && (u.canvas.removeEventListener("mousemove", u.mouseMove, e), u.canvas.removeEventListener("mouseout", u.mouseUp, e), u.canvas.removeEventListener("mouseup", u.mouseUp, e), u.canvas.removeEventListener("mousedown", u.mouseDown, e), u.canvas.removeEventListener("touchmove", u.mouseMove, e), u.canvas.removeEventListener("touchend", u.mouseUp, e), u.canvas.removeEventListener("touchstart", u.mouseDown, e)), u.uniforms.iDeviceOrientation.isNeeded && window.removeEventListener("deviceorientation", u.onDeviceOrientationChange, e), window.removeEventListener("resize", u.onResize, e);
      }), k(I(u), "onDeviceOrientationChange", function (e) {
        var r = e.alpha,
            t = e.beta,
            e = e.gamma;
        u.uniforms.iDeviceOrientation.value = [r, t, e, window.orientation || 0];
      }), k(I(u), "mouseDown", function (e) {
        var r = e.clientX || e.changedTouches[0].clientX,
            e = e.clientY || e.changedTouches[0].clientY,
            r = r - u.canvasPosition.left - window.pageXOffset,
            e = u.canvasPosition.height - e - u.canvasPosition.top - window.pageYOffset;
        u.mousedown = !0, u.uniforms.iMouse.value[2] = r, u.uniforms.iMouse.value[3] = e, u.lastMouseArr[0] = r, u.lastMouseArr[1] = e;
      }), k(I(u), "mouseMove", function (e) {
        u.canvasPosition = u.canvas.getBoundingClientRect();
        var r = u.props.lerp,
            t = e.clientX || e.changedTouches[0].clientX,
            e = e.clientY || e.changedTouches[0].clientY,
            t = t - u.canvasPosition.left,
            e = u.canvasPosition.height - e - u.canvasPosition.top;
        1 !== (void 0 === r ? 1 : r) ? (u.lastMouseArr[0] = t, u.lastMouseArr[1] = e) : (u.uniforms.iMouse.value[0] = t, u.uniforms.iMouse.value[1] = e);
      }), k(I(u), "mouseUp", function (e) {
        u.uniforms.iMouse.value[2] = 0, u.uniforms.iMouse.value[3] = 0;
      }), k(I(u), "onResize", function () {
        var e = I(u).gl,
            r = u.props.devicePixelRatio,
            t = void 0 === r ? 1 : r;
        u.canvasPosition = u.canvas.getBoundingClientRect();
        var r = t,
            t = Math.floor(u.canvasPosition.width * r),
            r = Math.floor(u.canvasPosition.height * r);
        e.canvas.width = t, e.canvas.height = r, u.uniforms.iResolution.isNeeded && (r = e.getUniformLocation(u.shaderProgram, G), e.uniform2fv(r, [e.canvas.width, e.canvas.height]));
      }), k(I(u), "drawScene", function (e) {
        var r = I(u).gl,
            t = u.props.lerp,
            t = void 0 === t ? 1 : t;
        r.viewport(0, 0, r.drawingBufferWidth, r.drawingBufferHeight), r.clear(r.COLOR_BUFFER_BIT | r.DEPTH_BUFFER_BIT), r.bindBuffer(r.ARRAY_BUFFER, u.squareVerticesBuffer), r.vertexAttribPointer(u.vertexPositionAttribute, 3, r.FLOAT, !1, 0, 0), u.setUniforms(e), r.drawArrays(r.TRIANGLE_STRIP, 0, 4), u.uniforms.iMouse.isNeeded && 1 !== t && (u.uniforms.iMouse.value[0] = X(u.uniforms.iMouse.value[0], u.lastMouseArr[0], t), u.uniforms.iMouse.value[1] = X(u.uniforms.iMouse.value[1], u.lastMouseArr[1], t)), u.animFrameId = requestAnimationFrame(u.drawScene);
      }), k(I(u), "createShader", function (e, r) {
        var t = I(u).gl,
            e = t.createShader(e);
        return t.shaderSource(e, r), t.compileShader(e), t.getShaderParameter(e, t.COMPILE_STATUS) || (console.warn(b(p = p || U(["Error compiling the shader:"])), r), r = t.getShaderInfoLog(e), t.deleteShader(e), console.error(b("Shader compiler log: ".concat(r)))), e;
      }), k(I(u), "initShaders", function (e) {
        var r = e.fs,
            t = e.vs,
            e = I(u).gl,
            r = u.createShader(e.FRAGMENT_SHADER, r),
            t = u.createShader(e.VERTEX_SHADER, t);
        u.shaderProgram = e.createProgram(), e.attachShader(u.shaderProgram, t), e.attachShader(u.shaderProgram, r), e.linkProgram(u.shaderProgram), e.getProgramParameter(u.shaderProgram, e.LINK_STATUS) ? (e.useProgram(u.shaderProgram), u.vertexPositionAttribute = e.getAttribLocation(u.shaderProgram, "aVertexPosition"), e.enableVertexAttribArray(u.vertexPositionAttribute)) : console.error(b("Unable to initialize the shader program: ".concat(e.getProgramInfoLog(u.shaderProgram))));
      }), k(I(u), "processCustomUniforms", function () {
        var e = u.props.uniforms;
        e && Object.keys(e).forEach(function (e, r) {
          var t,
              n,
              i = u.props.uniforms[e],
              o = i.value,
              a = i.type,
              s = m(a);
          s && (t = {}, a.includes("Matrix") ? (n = a.length, i = a.charAt(n - 3), n = Math.floor(o.length / (i * i)), o.length > i * i && (t.arraySize = "[".concat(n, "]"))) : a.includes("v") && o.length > a.charAt(0) && (t.arraySize = "[".concat(Math.floor(o.length / a.charAt(0)), "]")), u.uniforms[e] = S({
            type: s,
            isNeeded: !1,
            value: o
          }, t));
        });
      }), k(I(u), "processTextures", function () {
        var t = I(u).gl,
            e = u.props,
            r = e.textures,
            n = e.onDoneLoadingTextures;
        r && 0 < r.length ? (u.uniforms["".concat(V)] = {
          type: "vec3",
          isNeeded: !1,
          arraySize: "[".concat(r.length, "]"),
          value: []
        }, r = r.map(function (e, r) {
          return u.uniforms["".concat("iChannel").concat(r)] = {
            type: "sampler2D",
            isNeeded: !1
          }, u.setupChannelRes(e, r), u.texturesArr[r] = new d(t), u.texturesArr[r].load(e, r).then(function (e) {
            return u.setupChannelRes(e, r);
          });
        }), Promise.all(r).then(function () {
          return n && n();
        }).catch(function (e) {
          console.error(e), n && n();
        })) : n && n();
      }), k(I(u), "preProcessShaders", function (i, e) {
        var r = u.props,
            t = r.precision,
            n = r.devicePixelRatio,
            r = void 0 === n ? 1 : n,
            n = "#define DPR ".concat(r.toFixed(1), "\n"),
            r = z.includes(t),
            o = "precision ".concat(r ? t : z[1], " float;\n");
        r || console.warn(b(_ = _ || U(["wrong precision type ", ", please make sure to pass one of a valid precision lowp, mediump, highp, by default you shader precision will be set to highp."]), t));
        var a = o.concat(n).concat(i).replace(/texture\(/g, "texture2D("),
            s = a.lastIndexOf(o);
        Object.keys(u.uniforms).forEach(function (e) {
          var r, t, n;
          i.includes(e) && (r = a, t = "uniform ".concat(u.uniforms[e].type, " ").concat(e).concat(u.uniforms[e].arraySize || "", "; \n"), n = s + o.length, a = 0 < n ? r.substring(0, n) + t + r.substring(n, r.length) : t + r, u.uniforms[e].isNeeded = !0);
        });
        n = /mainImage/.test(i);
        return {
          fs: a = n ? a.concat("\nvoid main(void){\n    vec4 color = vec4(0.0,0.0,0.0,1.0);\n    mainImage( color, gl_FragCoord.xy );\n    gl_FragColor = color;\n}") : a,
          vs: e
        };
      }), k(I(u), "setUniforms", function (e) {
        var r,
            t,
            n,
            i,
            o,
            s = I(u).gl,
            a = u.lastTime ? (e - u.lastTime) / 1000 : 0;
        u.lastTime = e, u.props.uniforms && Object.keys(u.props.uniforms).forEach(function (e) {
          var r = u.props.uniforms[e];
          u.uniforms[e].isNeeded && (e = s.getUniformLocation(u.shaderProgram, e), function (e, r, t, n) {
            switch (t) {
              case "1f":
                e.uniform1f(r, n);
                break;

              case "2f":
                e.uniform2f(r, n[0], n[1]);
                break;

              case "3f":
                e.uniform3f(r, n[0], n[1], n[2]);
                break;

              case "4f":
                e.uniform4f(r, n[0], n[1], n[2], n[3]);
                break;

              case "1i":
                e.uniform1i(r, n);
                break;

              case "2i":
                e.uniform2i(r, n[0], n[1]);
                break;

              case "3i":
                e.uniform3i(r, n[0], n[1], n[2]);
                break;

              case "4i":
                e.uniform3i(r, n[0], n[1], n[2], n[3]);
                break;

              case "1iv":
                e.uniform1iv(r, n);
                break;

              case "2iv":
                e.uniform2iv(r, n);
                break;

              case "3iv":
                e.uniform3iv(r, n);
                break;

              case "4iv":
                e.uniform4iv(r, n);
                break;

              case "1fv":
                e.uniform1fv(r, n);
                break;

              case "2fv":
                e.uniform2fv(r, n);
                break;

              case "3fv":
                e.uniform3fv(r, n);
                break;

              case "4fv":
                e.uniform4fv(r, n);
                break;

              case "Matrix2fv":
                e.uniformMatrix2fv(r, !1, n);
                break;

              case "Matrix3fv":
                e.uniformMatrix3fv(r, !1, n);
                break;

              case "Matrix4fv":
                e.uniformMatrix4fv(r, !1, n);
            }
          }(s, e, r.type, r.value));
        }), u.uniforms.iMouse.isNeeded && (r = s.getUniformLocation(u.shaderProgram, "iMouse"), s.uniform4fv(r, u.uniforms.iMouse.value)), u.uniforms.iChannelResolution && u.uniforms.iChannelResolution.isNeeded && (r = s.getUniformLocation(u.shaderProgram, V), s.uniform3fv(r, u.uniforms.iChannelResolution.value)), u.uniforms.iDeviceOrientation.isNeeded && (i = s.getUniformLocation(u.shaderProgram, H), s.uniform4fv(i, u.uniforms.iDeviceOrientation.value)), u.uniforms.iTime.isNeeded && (t = s.getUniformLocation(u.shaderProgram, "iTime"), s.uniform1f(t, u.timer += a)), u.uniforms.iTimeDelta.isNeeded && (n = s.getUniformLocation(u.shaderProgram, Y), s.uniform1f(n, a)), u.uniforms.iDate.isNeeded && (t = (i = new Date()).getMonth() + 1, n = i.getDate(), a = i.getFullYear(), o = 60 * i.getHours() * 60 + 60 * i.getMinutes() + i.getSeconds() + 0.001 * i.getMilliseconds(), i = s.getUniformLocation(u.shaderProgram, "iDate"), s.uniform4fv(i, [a, t, n, o])), u.uniforms.iFrame.isNeeded && (o = s.getUniformLocation(u.shaderProgram, "iFrame"), s.uniform1i(o, u.uniforms.iFrame.value++)), 0 < u.texturesArr.length && u.texturesArr.forEach(function (e, r) {
          var t,
              n = e.isVideo,
              i = e._webglTexture,
              o = e.source,
              a = e.flipY;
          e.isLoaded && u.uniforms["iChannel".concat(r)].isNeeded && (t = s.getUniformLocation(u.shaderProgram, "iChannel".concat(r)), s.activeTexture(s["TEXTURE".concat(r)]), s.bindTexture(s.TEXTURE_2D, i), s.uniform1i(t, r), n && e.updateTexture(i, o, a));
        });
      }), k(I(u), "registerCanvas", function (e) {
        u.canvas = e;
      }), k(I(u), "gl", void 0), k(I(u), "squareVerticesBuffer", void 0), k(I(u), "shaderProgram", void 0), k(I(u), "vertexPositionAttribute", void 0), k(I(u), "animFrameId", void 0), k(I(u), "timeoutId", void 0), k(I(u), "canvas", void 0), k(I(u), "mousedown", !1), k(I(u), "canvasPosition", void 0), k(I(u), "timer", 0), k(I(u), "lastMouseArr", [0, 0]), k(I(u), "texturesArr", []), k(I(u), "lastTime", 0), k(I(u), "render", function () {
        var e = {
          glCanvas: S({
            height: "100%",
            width: "100%"
          }, u.props.style)
        };
        return a().createElement("canvas", {
          style: e.glCanvas,
          ref: u.registerCanvas
        });
      }), u.uniforms = (k(e = {}, "iTime", {
        type: "float",
        isNeeded: !1,
        value: 0
      }), k(e, Y, {
        type: "float",
        isNeeded: !1,
        value: 0
      }), k(e, "iDate", {
        type: "vec4",
        isNeeded: !1,
        value: [0, 0, 0, 0]
      }), k(e, "iMouse", {
        type: "vec4",
        isNeeded: !1,
        value: [0, 0, 0, 0]
      }), k(e, G, {
        type: "vec2",
        isNeeded: !1,
        value: [0, 0]
      }), k(e, "iFrame", {
        type: "int",
        isNeeded: !1,
        value: 0
      }), k(e, H, {
        type: "vec4",
        isNeeded: !1,
        value: [0, 0, 0, 0]
      }), e), u;
    }

    return e = i, (r = [{
      key: "componentWillUnmount",
      value: function () {
        var r = (this || _global).gl;
        r && (r.getExtension("WEBGL_lose_context").loseContext(), r.useProgram(null), r.deleteProgram((this || _global).shaderProgram), 0 < (this || _global).texturesArr.length && (this || _global).texturesArr.forEach(function (e) {
          r.deleteTexture(e._webglTexture);
        }), (this || _global).shaderProgram = null), this.removeEventListeners(), cancelAnimationFrame((this || _global).animFrameId);
      }
    }]) && F(e.prototype, r), t && F(e, t), Object.defineProperty(e, "prototype", {
      writable: !1
    }), i;
  }();

  k(q, "defaultProps", {
    textures: [],
    contextAttributes: {},
    devicePixelRatio: 1,
    vs: W,
    precision: "highp"
  }), exports = e;
})();

export default exports;